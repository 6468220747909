$(document).ready(function() {
  const textInputs = document.querySelectorAll("input[type='text']");
  const allowedInputs = {
    Enter: true,
    Control: true,
    Meta: true,
    Alt: true,
    ArrowLeft: true,
    ArrowRight: true,
    ArrowDown: true,
    ArrowUp: true,
    Esc: true,
    Shift: true,
    Tab: true,
    Delete: true,
    Backspace: true,
    " ": true
  };

  textInputs.forEach((input) => {
    input.addEventListener("keydown", (e) => {
      if (e.target.matches("input[data-pattern]")) {

        if (allowedInputs[e.key]) {
          return true;
        }

        const pattern = new RegExp(e.target.getAttribute("data-pattern"));
        const instructions = e.target.parentNode.querySelector("small");

        if (!pattern.test(e.key)) {
          e.preventDefault();

          if (instructions) {
            instructions.classList.add("instructions-error", "text-danger");
            instructions.classList.remove("text-muted");
          }

          return false;
        }


        if (instructions && instructions.classList.contains("instructions-error")) {
          instructions.classList.remove("instructions-error", "text-danger");
          instructions.classList.add("text-muted");
        }
      }
    });
  });
});
