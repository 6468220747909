function getState(id) {
  if (!sessionStorage[id]) {
    return undefined;
  }

  var state = JSON.parse(sessionStorage[id]);
  return state;
}

function storeState(id, slotName, value) {
  var state;
  if (!sessionStorage[id]) {
    state = {};
  } else {
    state = JSON.parse(sessionStorage[id]);
  }

  state[slotName]    = value;
  sessionStorage[id] = JSON.stringify(state);
}

function showStatus(id) {
  var state = getState(id);
  return _.indexOf(_.values(state), true) >= 0;
}

function clearRelatedSlots(slotId) {
  let slotType = getSlotType(slotId);

  if (slotType == "text") {
    // clear text selections
    $(`#${slotId}`).val("");
  }
  if (slotType == "radio") {
    // clear all radio selections
    $('input[name="slot.' + slotId + '"]')
      .prop("checked", false)
      .change();
  }
  if (slotType == "checkbox") {
    // clear all checkbox selections
    $('input[name="slot.' + slotId + '"]')
      .prop("checked", false)
      .change();
  }
  if (slotType == 'select') {
    // clear selection
    $(`#${slotId}`)
      .val(null)
      .trigger('change');
  }
}

function setupRules(id, rules) {
  var $showIfSlot = $("#" + id);
  var slotRules = _.keys(rules);

  _.forEach(slotRules, function (slotName) {
    var type = getSlotType(slotName);
    var values = rules[slotName];

    if (type == "radio") {
      // Radio inputs need to be found by name
      var $selector = $("input[name$=" + slotName + "]");
      $selector
        .on("change", function () {
          var triggered = false;
          $selector.each(function () {
            var $radio = $(this);
            if ($radio.is(":checked")) {
              var val = $radio.val();

              // If val is all numbers convert. Otherwise indexOf will fail
              // as the YAML values array will be all ints... :/
              // This will give us support for custom values.
              if (/^\d+$/.test(val)) {
                val = parseInt(val);
              }

              if (_.indexOf(values, val) >= 0) {
                $showIfSlot.collapse("show");

                // this will show the show_if slot just below the element tht triggered it
                $(`#${id}`).appendTo($(`label[for="${slotName}-${values}"]`).parent());

                storeState(id, slotName, true);
                triggered = true;
                return false;
              }
            }
          });

          if (!triggered) {
            storeState(id, slotName, false);

            if (showStatus(id, slotName) == false) {
              let slotId = `${id}`.replace("-collapse", "");
              clearRelatedSlots(slotId);

              $showIfSlot.collapse("hide");
            }
          }
        })
        .trigger("change");
    } else {
      var $selector = $("input[name$=" + slotName + "]");
      if (type == 'select') {
        $selector  = $(`select[name="slot.${slotName}"]`);
      }

      $selector
        .on("change", function () {
          var val = $(this).val();
          if (/^\d+$/.test(val)) {
            val = parseInt(val);
          }

          if (type == 'checkbox' && values == val) {
            if ((_.indexOf(values, val) >= 0 && this.checked)) {
              show_slot(id, slotName, values, $showIfSlot);
            } else {
              storeState(id, slotName, false);
              if (showStatus(id, slotName) == false) {
                hide_slot(id, slotName, $showIfSlot);
              }
            }
          }

          if (type == 'select') {
            if (values == val) {
              show_slot(id, slotName, values, $showIfSlot);
            }
            else {
              hide_slot(id, slotName, $showIfSlot);
            }
          }

        })
        .trigger("change");
    }
  });
}

function show_slot(id, slotName, values,$showIfSlot) {
  storeState(id, slotName, true);
  $showIfSlot.collapse("show");

  // this will show the show_if slot just below the element tht triggered it
  // and also make sur that the show-if option is not clickable
  // (refer https://gh.sdintra.net/WebSurvey/websurvey/issues/425 for more details)
  $(`#${id}`).appendTo($(`label[for="${slotName}-${values}"]`).parent());

  return false;
}

function hide_slot(id, slotName, $showIfSlot) {
  storeState(id, slotName, false);

  if (showStatus(id, slotName) == false) {
    let slotId = `${id}`.replace('-collapse','');
    clearRelatedSlots(slotId);

    $showIfSlot.collapse('hide');
  }
}

function getSlotType(slotId) {
  var $ruleSlot = $("#" + slotId);

  // -1 finds the first radio/checkbox, as these ids get tagged with
  // an index, as fallback to the first selector failing.
  if (!$ruleSlot.length) {
    $ruleSlot = $("#" + slotId + "-1");
  }

  if (typeof $ruleSlot == "undefined") return undefined;
  if (!$ruleSlot.prop("tagName")) return undefined;

  var tag = $ruleSlot.prop("tagName").toLowerCase();

  if (tag == "input") {
    return $ruleSlot.attr("type");
  }

  return tag;
}
